import { Bar } from 'vue-chartjs'

export default {
    extends: Bar,
    props: {
        chartData: {
            type: String,
            default: null
        },
        options: {
            type: Object,
            default() {
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                };
            }
        }
    },
    watch: {
        chartData(val) {
            const parsedData = JSON.parse(val);
            this.renderChart(parsedData, this.options)
        },
    },
    mounted() {
        const parsedData = JSON.parse(this.chartData);
        this.renderChart(parsedData, this.options);
    }
}
